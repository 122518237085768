import React from "react"
//import loadable from '@loadable/component'
import Layout from "../components/Layout"
import { Container, Paper, Typography, Tabs, Tab,AppBar, Box } from "@material-ui/core"
import SwipeableViews from "react-swipeable-views"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import {FaCalendar} from 'react-icons/fa'
import { IoIosPaper } from "react-icons/io"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "64px",
    padding:10,
    [theme.breakpoints.down('sm')]:{
      marginTop:60
    },
    background:'#39A6A2'
  },
  outerIframe: {
    maxWidth: 770,
    maxHeight: 800,
    overflow: "hidden",
    marginLeft: "auto ",
    marginRight: "auto ",
  },
  innerIframe: {
    position: "relative",
    height: 0,
    "-webkit-overflow-scrolling": "touch",
    overflow: "auto",
    paddingBottom: "92%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "249%",
    },
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0,
    border: "none",
  },
}))


/*const LoadableCalendar = loadable(
  () => import('../components/Calendar'),
  {
    fallback: <CircularProgress/>
  })*/

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box style={{padding:0}} p={3}>{children}</Box>
    </Typography>
  )
} 

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
} 

export default function Porgram(props) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const theme = useTheme()
  
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }
  
  return (
    <Layout>
      <Container className={classes.root} maxWidth="lg">
        <Container maxWidth="md">
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              indicatorColor="secondary"
              textColor="secondary"
              onChange={handleChange}
              centered
            >
              <Tab
                label={<Typography>Calendario</Typography>}
                icon={<FaCalendar style={{ fontSize: "2em" }} />}
                {...a11yProps(0)}
              />

              <Tab
                label={<Typography>Charlas</Typography>}
                icon={<IoIosPaper style={{ fontSize: "2em" }} />}
                {...a11yProps(0)}
              />
            </Tabs>
          </AppBar>
          <Paper
            style={{
              padding: 15,
              marginBottom: 10,
              marginTop: 10,
              maxWidth: 1280,
            }}
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <div className={classes.outerIframe}>
                  <div className={classes.innerIframe}>
                    <iframe
                      id="programaFrame"
                      title="programa"
                      className={classes.iframe}
                      src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTcF-RzuKN7umk2MbBssRGL727ypsKvnoTl-E2macNM1zMBX_IftHwua1dcRWOkX4LWpjP2vwQg-__m/pubhtml?widget=true&amp;headers=false"
                    ></iframe>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <div
                  className={classes.outerIframe}
                  style={{ background: "#ddd" }}
                >
                  <div className={classes.innerIframe}>
                    <iframe
                      title="charlas"
                      id="charlasFrame"
                      className={classes.iframe}
                      src="https://drive.google.com/embeddedfolderview?id=1tlUFDFLZsJ5HShbPo-owQP3VfzWyFAaS#grid"
                    ></iframe>
                  </div>
                </div>
              </TabPanel>
            </SwipeableViews>
          </Paper>
        </Container>
      </Container>
    </Layout>
  )
}
